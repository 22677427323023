/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import './src/assets/scss/reset.scss';
import './src/assets/scss/global.scss';
import 'slick-carousel/slick/slick.css';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const shouldUpdateScroll = () => {
    window.scrollTo(0, 0);
    return false;
};

export const onRouteUpdate = () => {
    document.querySelector('body').classList.remove('navbar-menu-open');
};

export const wrapRootElement = ({ element }) => (
    <ParallaxProvider>{element}</ParallaxProvider>
);
